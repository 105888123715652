import { defineStore } from 'pinia'

export const useUserStore = defineStore('user', {
  state: () => ({
    currentUser: {},
    company: {},
  }),
  actions: {
    setCurrentBannerId(val) {
      this.currentUser['current_banner_id'] = val
    },
    updateMfaNumber(val) {
      this.currentUser['mfa_number'] = val
    }
  },
  getters: {
    admin: (state) => {
      return !!state.currentUser.admin
    },
    companyDisplayName: (state) => {
      return state.company?.display_name
    },
    companyName: (state) => {
      return state.company?.name
    },
    completedIntroSurvey: (state) => {
      return state.currentUser.completed_required_survey
    },
    currentBannerId: (state) => {
      return state.currentUser.current_banner_id
    },
    email: (state) => {
      return state.currentUser.email
    },
    externalId: (state) => {
      return state.currentUser.external_id
    },
    firstName: (state) => {
      return state.currentUser.name
    },
    firstSurveyRoute: (state) => {
      return state.currentUser.first_survey_route
    },
    fullName: (state) => {
      return state.currentUser.full_name
    },
    googleCalendarId: (state) => {
      return state.currentUser.google_calendar_id
    },
    googleCalendarTimeZone: (state) => {
      return state.currentUser.google_calendar_time_zone
    },
    googleConnected: (state) => {
      return state.currentUser.google_connected
    },
    hasRequests: (state) => {
      return state.currentUser['requested?']
    },
    id: (state) => {
      return state.currentUser.id
    },
    isAdmin: (state) => {
      return !!state.company?.is_admin
    },
    mfaNumber: (state) => {
      return state.currentUser.mfa_number
    },
    mfaAuthenticated: (state) => {
      if(state.company?.mfa_enabled) {
        return state.currentUser.mfa_authenticated
      } else {
        return true
      }
    },
    microsoftConnected: (state) => {
      return state.currentUser.microsoft_connected
    }
  }
});
